import React from "react"
import Slider from "react-slick";

import '../scss/logoSlider.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import santander from '../images/santander.svg';
import cencosud from '../images/cencosud.svg';
import naranja from '../images/naranja.svg';
import bbva from '../images/bbva.svg';
import patagonia from '../images/patagonia.svg';
import fravega from '../images/fravega.svg';
import supervielle from '../images/supervielle.svg';
import carrefour from '../images/carrefour.svg';
import tarshop from '../images/tarshop.svg';

export default class LogoSlider extends React.Component {

  render(){
    const settings = {
      dots: false,
      infinite: false,
      speed: 100,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    };
    return(
      <Slider className="logo--slider" {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}
